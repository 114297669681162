import { Badge, Button, Card, Input, message, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import Layout from '../../components/Layout';
import api from '../../services/api';

import { Container } from './styles';

interface Entity {
  _id: string;
  name: string;
  sectors: Array<{
    _id: string;
    name: string;
    color: string;
  }>;
}

const Entities: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [entities, setEntities] = useState<Entity[]>([]);

  const history = useHistory();

  useEffect(() => {
    async function loadData(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get('entities');

        setEntities(response.data);
      } catch (err) {
        message.error('Error ao carregar dados');
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  if (loading) {
    return (
      <Layout title="Entidades">
        <Spin style={{ marginTop: '50%' }} size="large" />
      </Layout>
    );
  }

  return (
    <Layout title="Entidades">
      <Container>
        <h2>Gerenciamento de Entidades</h2>

        <div id="controls">
          <Button
            icon={<MdAdd />}
            size="large"
            onClick={() => history.push('/entities/add')}
          >
            Adicionar
          </Button>

          <Input.Search
            placeholder="Pesquisar"
            size="large"
            style={{ maxWidth: 290 }}
          />
        </div>

        <div className="category-grid">
          {entities.map(entity => (
            <Link key={entity._id} to={`/entities/${entity._id}`}>
              <Card
                className="card"
                title={entity.name}
                style={{ width: 300, height: 185 }}
              >
                {entity.sectors.map((sector, index) => (
                  <div key={sector._id}>
                    {!(index >= 3) && (
                      <Badge
                        color={sector.color || 'cyan'}
                        text={sector.name}
                      />
                    )}
                  </div>
                ))}
                {entity.sectors.length > 3 && (
                  <div>+ {entity.sectors.length - 3}</div>
                )}
              </Card>
            </Link>
          ))}
        </div>
      </Container>
    </Layout>
  );
};

export default Entities;
