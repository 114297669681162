import styled from 'styled-components';

export const Container = styled.div`
  .title-group {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      margin-left: 8px;
    }
  }
`;

export const AddForm = styled.form`
  .input-group {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    margin-bottom: 10px;
    width: 100%;
  }

  .inputs-group {
    display: flex;
    gap: 16px;
  }

  .button-group {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    button {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      font-weight: bold;
    }
  }
`;
