import styled from 'styled-components';

export const Container = styled.div`
  .save-buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
