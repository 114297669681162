import { Button, DatePicker, Divider, message } from 'antd';
import React, { useState } from 'react';
import moment from 'moment';
import Layout from '../../components/Layout';

import { Container } from './styles';
import api from '../../services/api';

const Options: React.FC = () => {
  const [startDate, setStartDate] = useState('');

  const handleUpdateStartDate = async (): Promise<void> => {
    try {
      await api.put('journey-tenant/start-date', {
        startDate: startDate ? moment(startDate, 'DD/MM/yyyy').toDate() : null,
      });

      message.success('Informações atualizadas com sucesso');
    } catch (error) {
      message.error('Erro ao atualizar dados');
    }
  };

  return (
    <Layout title="Opções">
      <Container>
        <h2>Opções do sistema</h2>

        <div className="options-form">
          <div className="input-group">
            <h4>Início dos Atividades</h4>
            <DatePicker
              defaultValue={
                startDate ? moment(startDate, 'DD/MM/yyyy') : undefined
              }
              format="DD/MM/yyyy"
              onChange={(_, dateString) => setStartDate(dateString)}
              allowClear
            />
          </div>

          <Divider />

          <div className="save-buttons">
            <Button
              htmlType="submit"
              type="primary"
              onClick={handleUpdateStartDate}
            >
              Salvar
            </Button>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Options;
