import Paragraph from 'antd/lib/typography/Paragraph';
import React from 'react';
import { AiOutlineMenuUnfold, AiOutlineMenuFold } from 'react-icons/ai';
import { useAuth } from '../../hooks/auth';
import { useSideMenu } from '../../hooks/sidemenu';
import { Container } from './styles';

interface HeaderProps {
  title: string;
}

const Header: React.FC<HeaderProps> = props => {
  const { title } = props;

  const { handleToggleDrawer, isDrawerOpen } = useSideMenu();

  const { tenant } = useAuth();

  return (
    <Container>
      <div className="title-control">
        <button type="button" onClick={handleToggleDrawer}>
          {isDrawerOpen ? <AiOutlineMenuFold /> : <AiOutlineMenuUnfold />}
        </button>
        <h1>{title}</h1>
      </div>

      <h2>
        <Paragraph copyable={{ text: tenant._id }}>
          {tenant?.shortName}
        </Paragraph>
      </h2>
    </Container>
  );
};

export default Header;
