import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 10vh;

  > img {
    width: 300px;
    margin: 50px 0;
  }

  > h1 {
    margin-top: 20px;
    font-size: 24px;
    margin-bottom: 20px;
  }
`;

export const Content = styled.div`
  background-color: #fbfbfb;
  padding: 30px;
  border-radius: 6px;
  width: 100%;
  max-width: 1100px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);

  display: flex;
  flex-direction: column;

  .steps-content {
    margin-bottom: 20px;
  }

  .confirm-data {
    font-size: 14px;
    display: flex;
    flex-direction: column;

    .data {
      display: flex;
      gap: 20px;

      div {
        strong {
          font-size: 14px;
        }
        display: flex;
        flex-direction: column;
      }
    }

    h4 {
      font-size: 15px;
    }
  }

  .steps-action {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
