import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'antd/dist/antd.css';
import 'moment/locale/pt-br';
import locale from 'antd/lib/locale/pt_BR';
import { ConfigProvider } from 'antd';
import GlobalStyle from './styles/global';
import Routes from './routes';
import AppProvider from './hooks';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ConfigProvider locale={locale}>
        <GlobalStyle />
        <AppProvider>
          <Routes />
        </AppProvider>
      </ConfigProvider>
    </BrowserRouter>
  );
};

export default App;
