import { Button, Input, message, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { MdAdd, MdEdit } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';

import Layout from '../../components/Layout';
import api from '../../services/api';
import { getYouTubeId } from '../../utils/getVideoID';

import { Container, VideoCard } from './styles';

interface Video {
  _id: string;
  title: string;
  quadrant: {
    name: string;
  };
  videoUrl: string;
}

const Videos: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState<Video[]>([]);

  const history = useHistory();

  useEffect(() => {
    async function loadData(): Promise<void> {
      setLoading(true);

      try {
        const response = await api.get('/videos');

        setVideos(response.data);
      } catch (err) {
        message.error('Falha ao carregar dados');
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  if (loading) {
    return (
      <Layout title="Videos">
        <Spin style={{ marginTop: '50%' }} size="large" />
      </Layout>
    );
  }

  return (
    <Layout title="Materiais e links">
      <Container>
        <h2>Gerenciamento de Videos</h2>

        <div id="controls">
          <Button
            icon={<MdAdd />}
            size="large"
            onClick={() => history.push('/videos/add')}
          >
            Adicionar
          </Button>

          <Input.Search
            placeholder="Pesquisar"
            size="large"
            style={{ maxWidth: 290 }}
          />
        </div>

        <div className="video-grid">
          {videos.map(video => (
            <VideoCard key={video._id}>
              <h2>{video.title}</h2>
              <div id="video-data">
                <img
                  src={`https://img.youtube.com/vi/${getYouTubeId(
                    video.videoUrl,
                  )}/0.jpg`}
                  alt={video.title}
                />

                <div>
                  <strong>Quadrante: {video.quadrant.name}</strong>

                  <span>
                    Link:{' '}
                    <a target="_blank" rel="noreferrer" href={video.videoUrl}>
                      Abrir
                    </a>
                  </span>

                  <Link to={`videos/${video._id}`} className="edit">
                    Editar
                    <MdEdit />
                  </Link>
                </div>
              </div>
            </VideoCard>
          ))}
        </div>
      </Container>
    </Layout>
  );
};

export default Videos;
