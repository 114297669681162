import React, { FormEvent, useState } from 'react';
import { Button, Card, Input, message, Select, Steps } from 'antd';
import MaskedInput from 'antd-mask-input';

import { useHistory } from 'react-router-dom';
import { Container, Content } from './styles';
import { AddForm } from '../Users/UsersForm/styles';
import logoImg from '../../assets/images/reconectme_logo_positivo.png';
import api from '../../services/api';

const { Step } = Steps;

const steps = [
  {
    title: 'Empresa',
  },
  {
    title: 'Usuário',
  },
  {
    title: 'Finalizar',
  },
];

const SignUp: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const [tenantName, setTenantName] = useState('');
  const [shortName, setShortName] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [segment, setSegment] = useState('default');
  const [userName, setUserName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [email, setEmail] = useState('');
  const [ddd, setDdd] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [sex, setSex] = useState('');
  const [birthdate, setBirthdate] = useState('');

  const history = useHistory();

  const handleNextStep = (): void => {
    if (currentStep === 0) {
      if (!tenantName || !shortName || !cnpj) {
        message.error('Preencha todos os campos obrigatórios (*)');
        return;
      }
    } else if (currentStep === 1) {
      if (!userName || !email || !password || !confirmPassword) {
        message.error('Preencha todos os campos obrigatórios (*)');
        return;
      }

      if (password !== confirmPassword) {
        message.error('As senhas não conferem');
        return;
      }
    }
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = (): void => {
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();

    api
      .post('/users-tenants/sign-up', {
        tenant: {
          cnpj,
          name: tenantName,
          shortName,
          segment,
        },
        user: {
          firstName: userName,
          lastName: userLastName,
          email,
          password,
          confirmPassword,
          birthdate,
          sex,
        },
      })
      .then(() => {
        message.success('Cadastro Completo!');

        history.push('/');
      })
      .catch(err =>
        message.error(`Erro no cadastro ${err.response.data.message}`),
      );
  };

  return (
    <Container>
      <img src={logoImg} alt="Reconect ME" />
      <h1>Cadastre-se</h1>

      <Content>
        <Steps current={currentStep}>
          {steps.map(step => (
            <Step key={step.title} title={step.title} />
          ))}
        </Steps>

        <AddForm onSubmit={handleSubmit}>
          <div className="steps-content">
            {(() => {
              switch (currentStep) {
                case 0:
                  return (
                    <div className="form-group">
                      <div className="inputs-group">
                        <div className="input-group">
                          <h4>Nome *</h4>
                          <Input
                            value={tenantName}
                            onChange={e => setTenantName(e.target.value)}
                            type="text"
                            required
                          />
                        </div>
                        <div className="input-group">
                          <h4>Nome Fantasia *</h4>
                          <Input
                            value={shortName}
                            onChange={e => setShortName(e.target.value)}
                            type="text"
                            required
                          />
                        </div>
                      </div>
                      <div className="inputs-group">
                        <div className="input-group">
                          <h4>CNPJ *</h4>
                          <MaskedInput
                            value={cnpj}
                            onChange={e => setCnpj(e.target.value)}
                            mask="11.111.111/1111-11"
                            type="text"
                            required
                          />
                        </div>
                        <div className="input-group">
                          <h4>Segmento *</h4>
                          <Select value={segment} onChange={e => setSegment(e)}>
                            <Select.Option value="coach">Coach</Select.Option>
                            <Select.Option value="health">Saúde</Select.Option>
                            <Select.Option value="beauty">Beleza</Select.Option>
                            <Select.Option value="default">
                              Outros
                            </Select.Option>
                          </Select>
                        </div>
                      </div>
                    </div>
                  );

                case 1:
                  return (
                    <div className="form-group">
                      <div className="inputs-group">
                        <div className="input-group">
                          <h4>Nome *</h4>
                          <Input
                            value={userName}
                            onChange={e => setUserName(e.target.value)}
                            type="text"
                            required
                          />
                        </div>
                        <div className="input-group">
                          <h4>Sobrenome</h4>
                          <Input
                            value={userLastName}
                            onChange={e => setUserLastName(e.target.value)}
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="inputs-group">
                        <div className="input-group">
                          <h4>Email *</h4>
                          <Input
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            type="email"
                            required
                          />
                        </div>
                        <div className="input-group w-20">
                          <h4>DDD</h4>
                          <MaskedInput
                            value={ddd}
                            onChange={e => setDdd(e.target.value)}
                            mask="11"
                            type="text"
                          />
                        </div>
                        <div className="input-group">
                          <h4>Telefone</h4>
                          <MaskedInput
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                            mask="11111-1111"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="inputs-group">
                        <div className="input-group">
                          <h4>Nascimento *</h4>
                          <Input
                            type="date"
                            value={birthdate}
                            onChange={e => setBirthdate(e.target.value)}
                          />
                        </div>
                        <div className="input-group">
                          <h4>Sexo *</h4>
                          <Select onChange={e => setSex(e)} value={sex}>
                            <Select.Option value="M">Masculino</Select.Option>
                            <Select.Option value="F">Feminino</Select.Option>
                          </Select>
                        </div>
                      </div>
                      <div className="inputs-group">
                        <div className="input-group">
                          <h4>Senha *</h4>
                          <Input.Password
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            required
                          />
                        </div>
                        <div className="input-group">
                          <h4>Confirmar Senha *</h4>
                          <Input.Password
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  );

                case 2:
                  return (
                    <div className="confirm-data">
                      <h3>Conferir dados</h3>
                      <div className="data">
                        <Card>
                          <h4>Empresa:</h4>
                          <span>
                            <strong>Nome: </strong>
                            {tenantName}
                          </span>
                          <span>
                            <strong>Nome Fantasia: </strong>
                            {shortName}
                          </span>
                          <span>
                            <strong>CNPJ: </strong>
                            {cnpj}
                          </span>
                          <span>
                            <strong>Segmento: </strong>
                            {segment}
                          </span>
                        </Card>
                        <Card>
                          <h4>Usuário:</h4>
                          <span>
                            <strong>Nome: </strong>
                            {userName}
                          </span>
                          <span>
                            <strong>Sobrenome: </strong>
                            {userLastName}
                          </span>
                          <span>
                            <strong>Email: </strong>
                            {email}
                          </span>
                          <span>
                            <strong>Telefone: </strong>({ddd}) {phone}
                          </span>
                        </Card>
                      </div>
                    </div>
                  );

                default:
                  return <></>;
              }
            })()}
          </div>

          <div className="steps-action">
            {currentStep > 0 && (
              <Button
                style={{ margin: '0 8px' }}
                onClick={() => handlePreviousStep()}
              >
                Anterior
              </Button>
            )}
            {currentStep === 0 && (
              <Button
                style={{ margin: '0 8px' }}
                onClick={() => history.goBack()}
              >
                Cancelar
              </Button>
            )}
            {currentStep < steps.length - 1 && (
              <Button type="primary" onClick={() => handleNextStep()}>
                Próximo
              </Button>
            )}
            {currentStep === steps.length - 1 && (
              <Button type="primary" htmlType="submit">
                Finalizar
              </Button>
            )}
          </div>
        </AddForm>
      </Content>
    </Container>
  );
};

export default SignUp;
