import React from 'react';
import { FormEvent, useState } from 'react';
import { Button, Input, message, Steps } from 'antd';

import { useHistory } from 'react-router-dom';
import { Container, Content, AddForm } from './styles';
import logoImg from '../../assets/images/reconectme_logo_positivo.png';
import api from '../../services/api';

const { Step } = Steps;

const steps = [
  {
    title: 'E-mail',
  },
  {
    title: 'Código',
  },
  {
    title: 'Redefinir senha',
  },
];

const ForgotPassword: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [token, setToken] = useState('');

  const history = useHistory();

  const handleNextStep = (): void => {
    if (currentStep === 0) {
      if (!email) {
        message.error('Preencha todos os campos obrigatórios (*)');
        return;
      }
    }

    if (currentStep === 1) {
      if (!token) {
        message.error('Preencha todos os campos obrigatórios (*)');
        return;
      }
      // Validar o código
    }

    if (currentStep === 2) {
      if (!password || !confirmPassword) {
        message.error('Preencha todos os campos obrigatórios (*)');
        return;
      }
      if (password !== confirmPassword) {
        message.error('As senhas não conferem');
        return;
      }
    }
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = (): void => {
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();

    switch (currentStep) {
      case 1:
        api
          .post('/forgot-password', {
            email,
          })
          .catch(err =>
            message.error(
              `Erro na mudança de senha ${err.response.data.message}`,
            ),
          );
        break;

      case 2:
        api
          .post('/reset-password', {
            email,
            password,
            confirmPassword,
            token,
          })
          .then(() => {
            message.success('Senha alterada com sucesso!');
            history.push('/');
          })
          .catch(err =>
            message.error(
              `Erro na mudança de senha ${err.response.data.message}`,
            ),
          );
        break;

      default:
        message.error('Erro na mudança de senha');
    }
  };

  return (
    <Container>
      <img src={logoImg} alt="Reconect ME" />
      <h1>Recuperar senha</h1>

      <Content>
        <Steps current={currentStep}>
          {steps.map(step => (
            <Step key={step.title} title={step.title} />
          ))}
        </Steps>

        <AddForm onSubmit={handleSubmit}>
          <div className="steps-content">
            {(() => {
              switch (currentStep) {
                case 0:
                  return (
                    <div className="form-group">
                      <h3>Digite seu E-mail</h3>
                      <div className="inputs-group">
                        <div className="input-group">
                          <h4>E-mail *</h4>
                          <Input
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            type="email"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  );

                case 1:
                  return (
                    <div className="form-group">
                      <h3>Digite o código que enviamos para seu E-mail</h3>
                      <div className="inputs-group">
                        <div className="input-group w-20">
                          <h4>Código *</h4>
                          <Input
                            value={token}
                            onChange={e => setToken(e.target.value)}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  );

                case 2:
                  return (
                    <div className="form-group">
                      <h3>Digite sua nova senha</h3>
                      <div className="inputs-group">
                        <div className="input-group w-20">
                          <h4>Senha *</h4>
                          <Input
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="inputs-group">
                        <div className="input-group w-20">
                          <h4>Confirmar Senha *</h4>
                          <Input
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  );

                default:
                  return <></>;
              }
            })()}
          </div>

          <div className="steps-action">
            {currentStep > 0 && (
              <Button
                style={{ margin: '0 8px' }}
                onClick={() => handlePreviousStep()}
              >
                Anterior
              </Button>
            )}
            {currentStep === 0 && (
              <Button
                style={{ margin: '0 8px' }}
                onClick={() => history.goBack()}
              >
                Cancelar
              </Button>
            )}
            {currentStep < steps.length - 1 && (
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => {
                  handleNextStep();
                  handleSubmit;
                }}
              >
                Próximo
              </Button>
            )}
            {currentStep === steps.length - 1 && (
              <Button type="primary" htmlType="submit">
                Finalizar
              </Button>
            )}
          </div>
        </AddForm>
      </Content>
    </Container>
  );
};

export default ForgotPassword;
