/* eslint-disable prettier/prettier */
import React from 'react';
import { Button, Result } from 'antd';
import { useHistory } from 'react-router-dom';

const NotFound: React.FC = () => {
  const history = useHistory();

  const handleGoDashboard = (): void => {
    history.push('/dashboard');
  };

  return (
    <Result
      status="404"
      title="404"
      subTitle="Opa! Parece que esta a página não existe."
      extra={(
        <Button size="large" onClick={handleGoDashboard}>
          Voltar para Dashboard
        </Button>
      )}
    />
  );
};

export default NotFound;
