import styled from 'styled-components';

export const Container = styled.div`
  .chart {
    padding: 10px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
    margin-top: 20px;

    border-radius: 5px;
  }

  .update-button {
    background-color: #38a1d0;
  }
`;
