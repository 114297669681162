import React from 'react';
import Header from '../Header';
import SideMenu from '../SideMenu';

import { Container, Content, Menu, ContentPanel } from './styles';

interface LayoutProps {
  title: string;
}

const Layout: React.FC<LayoutProps> = ({ title, children }) => {
  return (
    <Container>
      <Menu>
        <SideMenu />
      </Menu>
      <Content>
        <Header title={title} />

        <ContentPanel>{children}</ContentPanel>
      </Content>
    </Container>
  );
};

export default Layout;
