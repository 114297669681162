import { Badge, Button, Divider, Input, message, Select, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { MdAdd, MdClose } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import Layout from '../../../components/Layout';
import api from '../../../services/api';

import { Container, AddForm } from './styles';

interface Quadrant {
  _id: string;
  name: string;
  color: string;
}

interface LifeArea {
  _id: string;
  name: string;
  color: string;
  description: string;
  quadrant: Quadrant;
}

const LifeAreasForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [lifeArea, setLifeArea] = useState<LifeArea>({} as LifeArea);
  const [quadrant, setQuadrant] = useState('');
  const [quadrants, setQuadrants] = useState<Quadrant[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const nameRef = useRef<Input>(null);
  const descriptionRef = useRef<TextArea>(null);
  const colorRef = useRef<Input>(null);

  const history = useHistory();
  const { id } = useParams() as { id: string };

  useEffect(() => {
    async function loadData(): Promise<void> {
      setLoading(true);
      try {
        const quadrantsResponse = await api.get('quadrants');

        setQuadrants(quadrantsResponse.data);

        if (id !== 'add') {
          setIsUpdating(true);
          const response = await api.get(`lifeAreas/${id}`);

          setLifeArea(response.data);
          setQuadrant(response.data.quadrant);
        }
      } catch (err) {
        message.error('Erro ao carregar dados');
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [id]);

  const handleAddLifeArea = useCallback(() => {
    setLoading(true);

    api
      .post('lifeAreas', {
        name: nameRef.current?.input.value,
        color: colorRef.current?.input.value || '#000',
        description: descriptionRef.current?.state.value,
        quadrant,
      })
      .then(() => {
        message.success('Área da vida criada com sucesso');
        history.push('/lifeAreas');
      })
      .catch(() => message.error('Erro ao criar área'))
      .finally(() => setLoading(false));
  }, [quadrant, history]);

  const handleUpdateLifeArea = useCallback(() => {
    setLoading(true);

    api
      .put(`lifeAreas/${id}`, {
        name: nameRef.current?.input.value,
        color: colorRef.current?.input.value || '#000',
        description: descriptionRef.current?.state.value,
        quadrant,
      })
      .then(() => {
        message.success('Área da vida atualizada com sucesso');
        history.push('/lifeAreas');
      })
      .catch(() => message.error('Erro ao atualizar área'))
      .finally(() => setLoading(false));
  }, [quadrant, history, id]);

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();

    if (!quadrant) {
      message.warning('Escolha um quadrante');
      return;
    }

    if (isUpdating) {
      handleUpdateLifeArea();
    } else {
      handleAddLifeArea();
    }
  };

  if (loading) {
    return (
      <Layout title="Áreas">
        <Spin style={{ marginTop: '50%' }} size="large" />
      </Layout>
    );
  }

  return (
    <Layout title="Áreas">
      <Container>
        <h2>{isUpdating ? 'Atualizar' : 'Adicionar'}</h2>

        <AddForm onSubmit={handleSubmit}>
          <div className="input-group">
            <h4>Nome</h4>
            <Input
              ref={nameRef}
              type="text"
              defaultValue={lifeArea.name}
              required
            />
          </div>

          <div className="input-group">
            <h4>Cor</h4>
            <Input
              ref={colorRef}
              type="color"
              defaultValue={lifeArea.color}
              required
            />
          </div>

          <div className="input-group">
            <h4>Quadrante</h4>
            <Select defaultValue={quadrant} onChange={e => setQuadrant(e)}>
              {quadrants.map(quadrantItem => (
                <Select.Option key={quadrantItem._id} value={quadrantItem._id}>
                  <Badge
                    color={quadrantItem.color || 'cyan'}
                    text={quadrantItem.name}
                  />
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className="inputs-group">
            <div className="input-group">
              <h4>Descrição</h4>
              <TextArea
                ref={descriptionRef}
                defaultValue={lifeArea.description}
              />
            </div>
          </div>

          <Divider />

          <div className="button-group">
            <Button
              icon={<MdClose />}
              type="primary"
              danger
              onClick={() => history.goBack()}
            >
              Cancelar
            </Button>
            <Button icon={<MdAdd />} type="primary" htmlType="submit">
              {isUpdating ? 'Atualizar' : 'Adicionar'}
            </Button>
          </div>
        </AddForm>
      </Container>
    </Layout>
  );
};

export default LifeAreasForm;
