import axios from 'axios';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import api from '../services/api';

interface User {
  firstName: string;
  lastName: string;
  tenant: string | Tenant;
  email: string;
  access: {
    master?: boolean;
    system?: boolean;
    admin?: boolean;
    blocked?: boolean;
  };
}

interface Tenant {
  _id: string;
  cnpj: string;
  name: string;
  shortName: string;
}

interface AuthState {
  token: string;
  user: User;
  tenant: Tenant;
}

interface SignInCredencials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  tenant: Tenant;
  signIn(credencials: SignInCredencials): Promise<void>;
  signOut(): void;
  updateUser(user: User): void;
}

const url =
  process.env.REACT_APP_ENVIRONMENT === 'development'
    ? 'http://localhost:3333'
    : process.env.REACT_APP_API_URL;

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@ReconectMeAdmin:token');
    const user = localStorage.getItem('@ReconectMeAdmin:user');
    const tenant = localStorage.getItem('@ReconectMeAdmin:tenant');

    if (token && user && tenant) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      api.defaults.baseURL = `${url}/${JSON.parse(user).tenant._id}`;

      return { token, user: JSON.parse(user), tenant: JSON.parse(tenant) };
    }

    return {} as AuthState;
  });

  useEffect(() => {
    async function verifyToken(): Promise<void> {
      const token = localStorage.getItem('@ReconectMeAdmin:token');

      if (token) {
        try {
          const response = await axios.post(`${url}/sessions/test-token`, {
            token,
          });

          const isTokenValid = response.data.isValid;

          if (!isTokenValid || response.status === 401) {
            throw new Error();
          }
        } catch (error) {
          localStorage.removeItem('@ReconectMeAdmin:token');
          localStorage.removeItem('@ReconectMeAdmin:user');
          localStorage.removeItem('@ReconectMeAdmin:tenant');

          api.defaults.baseURL = url;

          setData({} as AuthState);
        }
      }
    }

    verifyToken();
  }, []);

  const signIn = useCallback(async ({ email, password }): Promise<void> => {
    const response = await api.post('sessions/login', {
      email,
      password,
      isAdmin: true,
    });

    const { token, user } = response.data;

    localStorage.setItem('@ReconectMeAdmin:token', token);
    localStorage.setItem('@ReconectMeAdmin:user', JSON.stringify(user));
    localStorage.setItem(
      '@ReconectMeAdmin:tenant',
      JSON.stringify(user.tenant),
    );

    api.defaults.headers.authorization = `Bearer ${token}`;
    api.defaults.baseURL = `${url}/${user.tenant._id}`;

    setData({ token, user, tenant: user.tenant });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@ReconectMeAdmin:token');
    localStorage.removeItem('@ReconectMeAdmin:user');
    localStorage.removeItem('@ReconectMeAdmin:tenant');

    api.defaults.baseURL = url;

    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user: User) => {
      localStorage.setItem('@ReconectMeAdmin:user', JSON.stringify(user));

      setData({
        token: data.token,
        user,
        tenant: user.tenant as Tenant,
      });
    },
    [data.token],
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        updateUser,
        tenant: data.tenant,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}
