import { darken } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > h2 {
    margin-bottom: 20px;
  }

  button {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    background: #048ba8;
    color: #fff;
    border-radius: 4px;
    padding: 10px 20px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
    transition: 0.2s;

    svg {
      color: #fff;
    }

    &:hover {
      background: ${darken('#048ba8', 0.15)};
      color: #fff;
    }
  }

  #controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .quadrants-grid {
    margin-top: 20px;
    flex: 1;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    /* column-gap: 30px; */
    row-gap: 25px;

    a {
      width: fit-content;

      .card {
        border-radius: 4px;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
        transition: 0.2s;

        &:hover {
          background-color: ${darken('#fff', 0.018)};
        }
      }
    }
  }
`;
