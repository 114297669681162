import React, { FormEvent, useEffect, useRef, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  Divider,
  Input,
  List,
  message,
  Modal,
  Select,
  Spin,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useHistory, useParams } from 'react-router-dom';
import { MdAdd, MdClose, MdFilterList } from 'react-icons/md';
import { TiArrowBackOutline } from 'react-icons/ti';
import confirm from 'antd/lib/modal/confirm';
import Layout from '../../../components/Layout';

import { AddForm, Container } from './styles';
import api from '../../../services/api';
import { getYouTubeId } from '../../../utils/getVideoID';

interface Quadrant {
  _id: string;
  name: string;
  color: string;
}

interface LifeArea {
  _id: string;
  name: string;
  color: string;
  quadrant: string;
}

interface Video {
  _id: string;
  title: string;
  quadrant: string;
  lifeArea: string;
  videoUrl: string;
  description: string;
}

interface Challenge {
  _id: string;
  num: number;
  name: string;
  description?: string;
  benefits?: string;

  quadrant: string;
  lifeArea: string;
  video: string;

  level: 'easy' | 'medium' | 'hard';
}

const ChallengesForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [level, setLevel] = useState('');
  const [quadrants, setQuadrants] = useState<Quadrant[]>([]);
  const [lifeAreas, setLifeAreas] = useState<LifeArea[]>([]);
  const [quadrant, setQuadrant] = useState('');
  const [lifeArea, setLifeArea] = useState('');
  const [videoQuadrant, setVideoQuadrant] = useState('');
  const [videoLifeArea, setVideoLifeArea] = useState('');
  const [challenge, setChallenge] = useState<Challenge>({} as Challenge);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [videos, setVideos] = useState<Video[]>([]);
  const [selectedVideo, setSelectedVideo] = useState<Video>({} as Video);

  const nameRef = useRef<Input>(null);
  const descriptionRef = useRef<TextArea>(null);
  const benefitsRef = useRef<TextArea>(null);
  const numRef = useRef<Input>(null);
  const videoNameRef = useRef<Input>(null);

  const history = useHistory();
  const { id } = useParams() as { id: string };

  useEffect(() => {
    async function loadData(): Promise<void> {
      setLoading(true);

      try {
        const response = await api.get('quadrants');

        setQuadrants(response.data);

        const lifeAreasResponse = await api.get('lifeAreas');

        setLifeAreas(lifeAreasResponse.data);

        if (id !== 'add') {
          setIsUpdating(true);
          const challengeResponse = await api.get(`challenges/${id}`);

          setChallenge(challengeResponse.data);
          setQuadrant(challengeResponse.data.quadrant);
          setLifeArea(challengeResponse.data.lifeArea);
          setLevel(challengeResponse.data.level);
          await loadVideo(challengeResponse.data.video);

          if (challengeResponse.data.quadrant) {
            await handleLoadLifeAreas(challengeResponse.data.quadrant);
          }
        }
      } catch (err) {
        message.error('Falha ao carregar dados');
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [id]);

  const handleAddChallenge = (): void => {
    api
      .post('challenges', {
        name: nameRef.current?.input.value,
        description: descriptionRef.current?.state.value,
        benefits: benefitsRef.current?.state.value,
        num: numRef.current?.input.value,
        level,
        video: selectedVideo._id,
        quadrant,
        lifeArea,
      })
      .then(() => {
        message.success('Desafio criado com sucesso');
        history.push('/challenges');
      })
      .catch(() => message.error('Erro ao criar desafio'))
      .finally(() => setLoading(false));
  };

  const handleUpdateChallenge = (): void => {
    api
      .put(`challenges/${id}`, {
        name: nameRef.current?.input.value,
        description: descriptionRef.current?.state.value,
        benefits: benefitsRef.current?.state.value,
        num: numRef.current?.input.value,
        level,
        video: selectedVideo._id,
        quadrant,
        lifeArea,
      })
      .then(() => {
        message.success('Desafio atualizado com sucesso');
        history.push('/challenges');
      })
      .catch(() => message.error('Erro ao atualizar desafio'))
      .finally(() => setLoading(false));
  };

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();

    if (!quadrant || !lifeArea) {
      message.warning('Selecione um quadrante e uma área da vida');
      return;
    }

    if (isUpdating) {
      handleUpdateChallenge();
    } else {
      handleAddChallenge();
    }
  };

  const handleDeleteChallenge = async (): Promise<void> => {
    confirm({
      title: 'Você realmente deseja excluir este desafio?',
      okText: 'Sim',
      cancelText: 'Não!',
      onOk: async () => {
        try {
          const response = await api.get(
            `challenges/${challenge._id}/can-delete`,
          );

          const canDelete = response.data;

          if (canDelete) {
            await api.delete(`challenges/${challenge._id}`);
            message.success('Desafio excluído com sucesso');
            history.goBack();
          } else {
            message.warn(
              'Desafio não pode ser excluído pois já está sendo utilizado',
            );
          }
        } catch (error) {
          message.error('Erro ao deletar desafio');
        }
      },
    });
  };

  const handleLoadLifeAreas = async (quadrantId: string): Promise<void> => {
    const response = await api.get(`/lifeAreas-quadrants/${quadrantId}`);

    setLifeAreas(response.data);
  };

  const handleChangeQuadrant = async (value: string): Promise<void> => {
    setQuadrant(value);

    await handleLoadLifeAreas(value);
  };

  const handleChangeVideoQuadrant = async (value: string): Promise<void> => {
    setVideoQuadrant(value);

    await handleLoadLifeAreas(value);
  };

  const handleSearchVideos = async (): Promise<void> => {
    const videosResponse = await api.get('videos-search', {
      params: {
        name: videoNameRef.current?.input.value,
        quadrantId: videoQuadrant,
        lifeAreaId: videoLifeArea,
      },
    });

    setVideos(videosResponse.data);
    setSelectedVideo({} as Video);
    setIsModalVisible(false);
  };

  const loadVideo = async (videoId: string): Promise<void> => {
    if (!videoId) {
      return;
    }
    const videoResponse = await api.get(`videos/${videoId}`);

    setSelectedVideo(videoResponse.data);
  };

  if (loading) {
    return (
      <Layout title="Atividades">
        <Spin style={{ marginTop: '50%' }} size="large" />
      </Layout>
    );
  }

  const handleSelectVideo = (video: Video): void => {
    setSelectedVideo(video);
    setQuadrant(video.quadrant);
    setLifeArea(video.lifeArea);

    setVideos([]);
  };

  return (
    <Layout title="Atividades">
      <Container>
        <div className="title-group">
          <TiArrowBackOutline
            onClick={() => history.goBack()}
            size={25}
            style={{ cursor: 'pointer' }}
          />
          {/* type="default" onClick={() => history.goBack()} */}
          <h2>{isUpdating ? 'Atualizar' : 'Adicionar'}</h2>
        </div>

        <AddForm onSubmit={handleSubmit}>
          <div className="input-group">
            <h4>Nome</h4>
            <Input
              ref={nameRef}
              defaultValue={challenge.name}
              type="text"
              required
            />
          </div>

          <div className="input-group">
            <h4>Número do dia (1 à 21)</h4>
            <Input
              ref={numRef}
              defaultValue={challenge.num}
              type="number"
              max={21}
              min={1}
              required
            />
          </div>

          <div className="input-group">
            <h4>Instruções/Descrição</h4>
            <TextArea
              ref={descriptionRef}
              defaultValue={challenge.description}
              rows={3}
              required
            />
          </div>

          <div className="input-group">
            <h4>Benefícios</h4>
            <TextArea
              ref={benefitsRef}
              defaultValue={challenge.benefits}
              rows={3}
              required
            />
          </div>

          <div className="input-group">
            <h4>Nível</h4>
            <Select value={level} onChange={e => setLevel(e)}>
              <Select.Option key="easy" value="easy">
                Fácil
              </Select.Option>
              <Select.Option key="medium" value="medium">
                Médio
              </Select.Option>
              <Select.Option key="hard" value="hard">
                Difícil
              </Select.Option>
            </Select>
          </div>

          <div className="inputs-group">
            <div className="input-group">
              <h4>Quadrante</h4>
              <Select value={quadrant} onChange={handleChangeQuadrant}>
                {quadrants.map(quadrantItem => (
                  <Select.Option
                    key={quadrantItem._id}
                    value={quadrantItem._id}
                  >
                    <Badge
                      color={quadrantItem.color || 'cyan'}
                      text={quadrantItem.name}
                    />
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="input-group">
              <h4>Área da Vida</h4>
              <Select value={lifeArea} onChange={e => setLifeArea(e)}>
                {lifeAreas.map(lifeAreaItem => (
                  <Select.Option
                    key={lifeAreaItem._id}
                    value={lifeAreaItem._id}
                  >
                    <Badge
                      color={lifeAreaItem.color || 'cyan'}
                      text={lifeAreaItem.name}
                    />
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>

          <Divider />

          <div className="form-group">
            <div>
              <h3>Vídeo(Opcional)</h3>
              <Button
                icon={<MdFilterList />}
                onClick={() => setIsModalVisible(true)}
              >
                Filtros
              </Button>
            </div>
            <Modal
              // eslint-disable-next-line prettier/prettier
              title={
                () => (
                  <>
                    <h3>Pesquisar Materiais e links</h3>
                    <span>Deixe em branco para ver todos</span>
                  </>
                )
                // eslint-disable-next-line prettier/prettier
              }
              centered
              visible={isModalVisible}
              onCancel={() => {
                setIsModalVisible(false);
                setVideoQuadrant('');
                setVideoLifeArea('');
              }}
              footer={[
                <Button
                  key="back"
                  onClick={() => {
                    setIsModalVisible(false);
                    setVideoQuadrant('');
                    setVideoLifeArea('');
                  }}
                >
                  Cancelar
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={handleSearchVideos}
                >
                  Aplicar
                </Button>,
              ]}
            >
              <div className="inputs-group">
                <div className="input-group">
                  <h4>Titulo</h4>
                  <Input ref={videoNameRef} type="text" />
                </div>
                <div className="input-group">
                  <h4>Quadrante</h4>
                  <Select
                    style={{ width: '100%' }}
                    value={videoQuadrant}
                    onChange={handleChangeVideoQuadrant}
                  >
                    {quadrants.map(quadrantItem => (
                      <Select.Option
                        key={quadrantItem._id}
                        value={quadrantItem._id}
                      >
                        <Badge
                          color={quadrantItem.color || 'cyan'}
                          text={quadrantItem.name}
                        />
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div className="input-group">
                  <h4>Área da Vida</h4>
                  <Select
                    style={{ width: '100%' }}
                    value={videoLifeArea}
                    onChange={e => setVideoLifeArea(e)}
                  >
                    {lifeAreas.map(lifeAreaItem => (
                      <Select.Option
                        key={lifeAreaItem._id}
                        value={lifeAreaItem._id}
                      >
                        <Badge
                          color={lifeAreaItem.color || 'cyan'}
                          text={lifeAreaItem.name}
                        />
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            </Modal>
            {selectedVideo.videoUrl && (
              <Card
                hoverable
                style={{
                  width: 240,
                  marginTop: 20,
                  border: '2px solid #3369cc',
                }}
                // eslint-disable-next-line prettier/prettier
                cover={
                  () => (
                    <img
                      alt={selectedVideo.title}
                      src={`https://img.youtube.com/vi/${getYouTubeId(
                        selectedVideo.videoUrl || '',
                      )}/0.jpg`}
                    />
                  )
                  // eslint-disable-next-line prettier/prettier
                }
              >
                <Card.Meta
                  title={selectedVideo.title}
                  description={selectedVideo.description}
                />
              </Card>
            )}
            {videos.length > 0 && (
              <>
                <strong>Escolha:</strong>
                <List
                  style={{ marginTop: 20 }}
                  grid={{ gutter: 20 }}
                  dataSource={videos}
                  renderItem={item => (
                    <List.Item>
                      <Card
                        onClick={() => handleSelectVideo(item)}
                        // eslint-disable-next-line prettier/prettier
                        cover={
                          () => (
                            <img
                              alt={item.title}
                              src={`https://img.youtube.com/vi/${getYouTubeId(
                                item.videoUrl,
                              )}/0.jpg`}
                            />
                          )
                          // eslint-disable-next-line prettier/prettier
                        }
                        style={{ width: 200 }}
                        hoverable
                      >
                        <Card.Meta title={item.title} />
                      </Card>
                    </List.Item>
                  )}
                />
              </>
            )}
          </div>

          <Divider />

          <div className="button-group">
            <Button
              icon={<MdClose />}
              type="primary"
              danger
              onClick={handleDeleteChallenge}
            >
              Excluir
            </Button>
            <Button icon={<MdAdd />} type="primary" htmlType="submit">
              {isUpdating ? 'Atualizar' : 'Adicionar'}
            </Button>
          </div>
        </AddForm>
      </Container>
    </Layout>
  );
};

export default ChallengesForm;
