import styled from 'styled-components';

export const Container = styled.div``;

export const AddForm = styled.form`
  margin-top: 20px;

  .input-group {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    margin-bottom: 10px;
    width: 100%;
  }

  .inputs-group {
    display: flex;
    gap: 16px;
  }

  .button-group {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    button {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      font-weight: bold;
    }
  }
`;

export const Sectors = styled.div`
  #title-control {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    h3 {
      font-size: 18px;
    }

    button {
      display: flex;
      align-items: center;
      padding: 0 8px;
    }
  }

  .sector-item-button {
    border: none;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
