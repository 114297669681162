import React from 'react';
import { MdDashboard, MdVideoLibrary, MdPerson } from 'react-icons/md';
import { CgLogOut, CgOptions } from 'react-icons/cg';
import { IoMdPin } from 'react-icons/io';
import { BsCollectionFill } from 'react-icons/bs';
import { AiFillAppstore, AiFillPieChart } from 'react-icons/ai';
import logoImg from '../../assets/images/reconectme_logo_positivo.png';
import logoImgIcon from '../../assets/images/reconectme_icone_positivo.png';

import { Container, MenuItem, MenuItemButton } from './styles';
import { useAuth } from '../../hooks/auth';
import { useSideMenu } from '../../hooks/sidemenu';

const SideMenu: React.FC = () => {
  const { signOut } = useAuth();
  const { isDrawerOpen } = useSideMenu();

  const handleLogOut = (): void => {
    signOut();
  };

  return (
    <Container $isOpen={isDrawerOpen}>
      <img src={isDrawerOpen ? logoImg : logoImgIcon} alt="ReconectMe" />

      <div className="line" />

      {/* <Tooltip placement="right" title="Dashboard" color="#7995f3" > */}
      <MenuItem $isOpen={isDrawerOpen} to="/dashboard">
        <MdDashboard />
        <span>Dashboard</span>
      </MenuItem>
      {/* </Tooltip> */}

      <MenuItem $isOpen={isDrawerOpen} to="/users">
        <MdPerson />
        <span>Usuários</span>
      </MenuItem>

      <MenuItem $isOpen={isDrawerOpen} to="/entities">
        <IoMdPin />
        <span>Entidades</span>
      </MenuItem>

      <MenuItem $isOpen={isDrawerOpen} to="/quadrants">
        <AiFillAppstore />
        <span>Quadrantes</span>
      </MenuItem>

      <MenuItem $isOpen={isDrawerOpen} to="/lifeAreas">
        <AiFillPieChart />
        <span>Áreas</span>
      </MenuItem>

      <MenuItem $isOpen={isDrawerOpen} to="/videos">
        <MdVideoLibrary />
        <span>Materiais e links</span>
      </MenuItem>

      <MenuItem $isOpen={isDrawerOpen} to="/challenges">
        <BsCollectionFill />
        <span>Atividades</span>
      </MenuItem>

      <MenuItem $isOpen={isDrawerOpen} to="/options">
        <CgOptions />
        <span>Opções</span>
      </MenuItem>

      <MenuItemButton $isOpen={isDrawerOpen} onClick={handleLogOut}>
        <CgLogOut />
        <span>Sair</span>
      </MenuItemButton>
    </Container>
  );
};

export default SideMenu;
