import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Dashboard from '../pages/Dashboard';
import NotFound from '../pages/NotFound';
import SignIn from '../pages/SignIn';
import Users from '../pages/Users';
import UsersForm from '../pages/Users/UsersForm';
import Videos from '../pages/Videos';
import VideoForm from '../pages/Videos/VideoForm';
import Entities from '../pages/Entities';
import EntitiesForm from '../pages/Entities/EntitiesForm';
import SignUp from '../pages/SignUp';
import Quadrants from '../pages/Quadrants';
import QuadrantsForm from '../pages/Quadrants/QuadrantsForm';
import LifeAreas from '../pages/LifeAreas';
import LifeAreasForm from '../pages/LifeAreas/LifeAreasForm';
import Challenges from '../pages/Challenges';
import ChallengesForm from '../pages/Challenges/ChallengesForm';
import Options from '../pages/Options';
import ForgotPassword from '../pages/ForgotPassword';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/sign-up" component={SignUp} />

    <Route path="/forgot-password" component={ForgotPassword} />

    <Route path="/dashboard" isPrivate component={Dashboard} />

    <Route path="/users" exact isPrivate component={Users} />
    <Route path="/users/:id" isPrivate component={UsersForm} />

    <Route path="/entities" exact isPrivate component={Entities} />
    <Route path="/entities/:id" isPrivate component={EntitiesForm} />

    <Route path="/quadrants" exact isPrivate component={Quadrants} />
    <Route path="/quadrants/:id" isPrivate component={QuadrantsForm} />

    <Route path="/lifeAreas" exact isPrivate component={LifeAreas} />
    <Route path="/lifeAreas/:id" isPrivate component={LifeAreasForm} />

    <Route path="/videos" exact isPrivate component={Videos} />
    <Route path="/videos/:id" isPrivate component={VideoForm} />

    <Route path="/challenges" exact isPrivate component={Challenges} />
    <Route path="/challenges/:id" isPrivate component={ChallengesForm} />

    <Route path="/options" isPrivate component={Options} />

    {/* <Route path="/life-circle" isPrivate component={LifeCircle} /> */}

    <Route component={NotFound} />
  </Switch>
);

export default Routes;
