import { Button, Card, Input, message, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { BsCollectionFill } from 'react-icons/bs';
import { MdAdd } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import Layout from '../../components/Layout';
import api from '../../services/api';
import { getYouTubeId } from '../../utils/getVideoID';

import { Container } from './styles';

interface Challenge {
  _id: string;

  num: number;
  name: string;
  description?: string;
  lifeArea: {
    name: string;
  };
  quadrant: {
    name: string;
  };

  video?: {
    _id: string;
    videoUrl: string;
  };

  level: 'easy' | 'medium' | 'hard';
}

const Challenges: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [challenges, setChallenges] = useState<Challenge[]>([]);

  const history = useHistory();

  useEffect(() => {
    async function loadData(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get('challenges');

        setChallenges(response.data);
      } catch (err) {
        message.error('Erro ao carregar dados');
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  if (loading) {
    return (
      <Layout title="Atividades">
        <Spin style={{ marginTop: '50%' }} size="large" />
      </Layout>
    );
  }

  return (
    <Layout title="Atividades">
      <Container>
        <h2>Gerenciamento de Atividades</h2>

        <div id="controls">
          <Button
            icon={<MdAdd />}
            size="large"
            onClick={() => history.push('/challenges/add')}
          >
            Adicionar
          </Button>

          <Input.Search
            placeholder="Pesquisar"
            size="large"
            style={{ maxWidth: 290 }}
          />
        </div>

        <div className="challenge-grid">
          {challenges.map(challenge => (
            <Card
              key={challenge._id}
              onClick={() => history.push(`challenges/${challenge._id}`)}
              hoverable
              style={{ width: 220 }}
              cover={
                challenge.video?.videoUrl ? (
                  <img
                    alt={challenge.name}
                    src={`https://img.youtube.com/vi/${getYouTubeId(
                      challenge.video.videoUrl,
                    )}/0.jpg`}
                  />
                ) : (
                  <BsCollectionFill size={164} color="#a6a6a6" />
                )
              }
            >
              <Card.Meta title={challenge.name} />
              <span>Quadrante: {challenge.quadrant.name}</span>
              <span>Área: {challenge.lifeArea.name}</span>
              <span>Dia: {challenge.num}</span>
            </Card>
          ))}
        </div>
      </Container>
    </Layout>
  );
};

export default Challenges;
