import React, {
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Badge,
  Button,
  Divider,
  Image,
  Input,
  message,
  Select,
  Spin,
} from 'antd';
import { MdAdd, MdClose } from 'react-icons/md';
import { TiArrowBackOutline } from 'react-icons/ti';
import { useHistory, useParams } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';
import confirm from 'antd/lib/modal/confirm';
import { getYouTubeId } from '../../../utils/getVideoID';

import Layout from '../../../components/Layout';

import { Container, AddForm, Preview } from './styles';
import api from '../../../services/api';

interface Quadrant {
  _id: string;
  name: string;
  color: string;
}

interface LifeArea {
  _id: string;
  name: string;
  color: string;
  quadrant: string;
}

interface Video {
  _id: string;
  title: string;
  quadrant: Quadrant;
  lifeArea: LifeArea;
  videoUrl: string;
  description: string;
}

const VideoForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [video, setVideo] = useState<Video>({} as Video);
  const [quadrants, setQuadrants] = useState<Quadrant[]>([]);
  const [lifeAreas, setLifeAreas] = useState<LifeArea[]>([]);
  const [linkUrl, setLinkUrl] = useState('');
  const [quadrant, setQuadrant] = useState('');
  const [lifeArea, setLifeArea] = useState('');

  const titleRef = useRef<Input>(null);
  const urlRef = useRef<Input>(null);
  const descriptionRef = useRef<TextArea>(null);

  const history = useHistory();
  const { id } = useParams() as { id: string };

  useEffect(() => {
    async function loadData(): Promise<void> {
      setLoading(true);

      try {
        const response = await api.get('quadrants');

        setQuadrants(response.data);

        const lifeAreasResponse = await api.get('lifeAreas');

        setLifeAreas(lifeAreasResponse.data);

        if (id !== 'add') {
          setIsUpdating(true);
          const videoResponse = await api.get(`videos/${id}`);

          setVideo(videoResponse.data);
          setQuadrant(videoResponse.data.quadrant);
          setLifeArea(videoResponse.data.lifeArea);
          setLinkUrl(videoResponse.data.videoUrl);

          if (videoResponse.data.quadrant) {
            await handleLoadLifeAreas(videoResponse.data.quadrant);
          }
        }
      } catch (err) {
        message.error('Falha ao carregar dados');
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [id]);

  const handleAddVideo = useCallback(() => {
    api
      .post('videos', {
        title: titleRef.current?.input.value,
        videoUrl: urlRef.current?.input.value,
        quadrant,
        lifeArea,
        description: descriptionRef.current?.state.value,
      })
      .then(() => {
        message.success('Video criado com sucesso');
        history.push('/videos');
      })
      .catch(() => message.error('Erro ao criar video'))
      .finally(() => setLoading(false));
  }, [quadrant, history, lifeArea]);

  const handleUpdateVideo = useCallback(() => {
    api
      .put(`videos/${id}`, {
        title: titleRef.current?.input.value,
        videoUrl: urlRef.current?.input.value,
        quadrant,
        lifeArea,
        description: descriptionRef.current?.state.value,
      })
      .then(() => {
        message.success('Video atualizado com sucesso');
        history.push('/videos');
      })
      .catch(() => message.error('Erro ao atualizar video'))
      .finally(() => setLoading(false));
  }, [quadrant, history, id, lifeArea]);

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();

    if (!quadrant) {
      message.warning('Selecione um quadrante');
      return;
    }

    if (isUpdating) {
      handleUpdateVideo();
    } else {
      handleAddVideo();
    }
  };

  const handleDeleteVideo = async (): Promise<void> => {
    confirm({
      title: 'Você realmente deseja excluir este vídeo?',
      okText: 'Sim',
      cancelText: 'Não!',
      onOk: async () => {
        try {
          const response = await api.get(`videos/${video._id}/can-delete`);

          const canDelete = response.data;

          if (canDelete) {
            await api.delete(`videos/${video._id}`);
            message.success('Vídeo excluído com sucesso');
            history.goBack();
          } else {
            message.warn(
              'Vídeo não pode ser excluído pois já está sendo utilizado',
            );
          }
        } catch (error) {
          message.error('Erro ao deletar vídeo');
        }
      },
    });
  };

  const handleLoadLifeAreas = async (quadrantId: string): Promise<void> => {
    const response = await api.get(`/lifeAreas-quadrants/${quadrantId}`);

    setLifeAreas(response.data);
  };

  const handleChangeQuadrant = async (value: string): Promise<void> => {
    setQuadrant(value);

    await handleLoadLifeAreas(value);
  };

  if (loading) {
    return (
      <Layout title="Materiais e links">
        <Spin style={{ marginTop: '50%' }} size="large" />
      </Layout>
    );
  }

  return (
    <Layout title="Materiais e links">
      <Container>
        <div className="title-group">
          <TiArrowBackOutline
            onClick={() => history.goBack()}
            size={25}
            style={{ cursor: 'pointer' }}
          />
          {/* type="default" onClick={() => history.goBack()} */}
          <h2>{isUpdating ? 'Atualizar' : 'Adicionar'}</h2>
        </div>

        <AddForm onSubmit={handleSubmit}>
          <div className="input-group">
            <h4>Título</h4>
            <Input
              ref={titleRef}
              defaultValue={video.title}
              type="text"
              required
            />
          </div>

          <div className="input-group">
            <h4>Link do vídeo</h4>
            <Input
              ref={urlRef}
              value={linkUrl}
              onChange={e => setLinkUrl(e.target.value)}
              type="text"
              required
            />
          </div>

          <div className="inputs-group">
            <div className="input-group">
              <h4>Quadrante</h4>
              <Select defaultValue={quadrant} onChange={handleChangeQuadrant}>
                {quadrants.map(quadrantItem => (
                  <Select.Option
                    key={quadrantItem._id}
                    value={quadrantItem._id}
                  >
                    <Badge
                      color={quadrantItem.color || 'cyan'}
                      text={quadrantItem.name}
                    />
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="input-group">
              <h4>Área da Vida</h4>
              <Select defaultValue={lifeArea} onChange={e => setLifeArea(e)}>
                {lifeAreas.map(lifeAreaItem => (
                  <Select.Option
                    key={lifeAreaItem._id}
                    value={lifeAreaItem._id}
                  >
                    <Badge
                      color={lifeAreaItem.color || 'cyan'}
                      text={lifeAreaItem.name}
                    />
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>

          <div className="input-group">
            <h4>Descrição/Instruções</h4>
            <TextArea
              ref={descriptionRef}
              defaultValue={video.description}
              rows={3}
              required
            />
          </div>

          <Divider />

          <Preview>
            <h3>Preview</h3>

            <Image
              src={`https://img.youtube.com/vi/${getYouTubeId(linkUrl)}/0.jpg`}
            />
          </Preview>

          <Divider />

          <div className="button-group">
            <Button
              icon={<MdClose />}
              type="default"
              danger
              onClick={handleDeleteVideo}
            >
              Excluir
            </Button>

            <Button icon={<MdAdd />} type="primary" htmlType="submit">
              {isUpdating ? 'Atualizar' : 'Adicionar'}
            </Button>
          </div>
        </AddForm>
      </Container>
    </Layout>
  );
};

export default VideoForm;
