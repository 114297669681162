import React, { useEffect, useState } from 'react';
import { format, formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { Button, Card, Input, message, Spin, Tooltip } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { MdAdd } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import Layout from '../../components/Layout';
import api from '../../services/api';

import { Container } from './styles';

interface IUser {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  lastAccessAt?: string;
  journey?: {
    completeDays: number[];
  };
}

const Users: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [usersList, setUsersList] = useState<IUser[]>([]);

  const history = useHistory();

  useEffect(() => {
    async function loadData(): Promise<void> {
      setLoading(true);

      try {
        const response = await api.get('/users');

        const users = response.data;

        setUsersList(users);
      } catch (err) {
        message.error('Falha ao carregar dados');
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  if (loading) {
    return (
      <Layout title="Usuários">
        <Spin style={{ marginTop: '50%' }} size="large" />
      </Layout>
    );
  }

  return (
    <Layout title="Usuários">
      <Container>
        <h2>Gerenciamento de Usuários</h2>

        <div id="controls">
          <Button
            icon={<MdAdd />}
            size="large"
            onClick={() => history.push('/users/add')}
          >
            Adicionar
          </Button>

          <Input.Search
            placeholder="Pesquisar"
            size="large"
            style={{ maxWidth: 290 }}
          />
        </div>

        <div className="users-grid">
          {usersList.map(user => (
            <Link to={`/users/${user._id}`} key={user._id}>
              <Card style={{ width: 300 }} className="card">
                <Meta
                  title={`${user.firstName} ${user.lastName}`}
                  description={user.email}
                />

                <p>Atividades: {user.journey?.completeDays.length || 0} / 21</p>
                {user.lastAccessAt ? (
                  <p>
                    <Tooltip
                      title={formatDistance(
                        new Date(user.lastAccessAt),
                        new Date(),
                        {
                          addSuffix: true,
                          locale: ptBR,
                        },
                      )}
                    >
                      <strong>
                        Último acesso em:{' '}
                        {format(
                          new Date(user.lastAccessAt),
                          'dd/MM/yyyy HH:mm',
                        )}
                      </strong>
                    </Tooltip>
                  </p>
                ) : (
                  <p>Nenhuma data de acesso disponível</p>
                )}
              </Card>
            </Link>
          ))}
        </div>
      </Container>
    </Layout>
  );
};

export default Users;
