import { Badge, Button, Card, Input, message, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import Layout from '../../components/Layout';
import api from '../../services/api';

import { Container } from './styles';

interface Quadrant {
  _id: string;
  name: string;
  color: string;
  description: string;
}

const Quadrants: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [quadrants, setQuadrants] = useState<Quadrant[]>([]);

  const history = useHistory();

  useEffect(() => {
    async function loadData(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get('quadrants');

        setQuadrants(response.data);
      } catch (err) {
        message.error('Erro ao carregar dados');
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  if (loading) {
    return (
      <Layout title="Quadrantes">
        <Spin style={{ marginTop: '50%' }} size="large" />
      </Layout>
    );
  }

  return (
    <Layout title="Quadrantes">
      <Container>
        <h2>Gerenciamento de Quadrantes</h2>

        <div id="controls">
          <Button
            icon={<MdAdd />}
            size="large"
            onClick={() => history.push('/quadrants/add')}
          >
            Adicionar
          </Button>

          <Input.Search
            placeholder="Pesquisar"
            size="large"
            style={{ maxWidth: 290 }}
          />
        </div>

        <div className="quadrants-grid">
          {quadrants.map(quadrant => (
            <Link key={quadrant._id} to={`/quadrants/${quadrant._id}`}>
              <Card
                className="card"
                // eslint-disable-next-line prettier/prettier
                title={(
                  <Badge
                    color={quadrant.color || 'cyan'}
                    text={quadrant.name}
                  />
                  // eslint-disable-next-line prettier/prettier
                )}
                style={{ width: 300 }}
              >
                <p>{quadrant.description}</p>
              </Card>
            </Link>
          ))}
        </div>
      </Container>
    </Layout>
  );
};

export default Quadrants;
