import {
  Badge,
  Button,
  Divider,
  Input,
  List,
  message,
  Modal,
  Select,
  Spin,
} from 'antd';
import MaskedInput from 'antd-mask-input';
import TextArea from 'antd/lib/input/TextArea';
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { MdAdd, MdClose } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import Layout from '../../../components/Layout';
import IEntity, { Sector } from '../../../interfaces/IEntity';
import api from '../../../services/api';
import states from '../../../utils/statesBR';

import { Container, AddForm, Sectors } from './styles';

const EntitiesForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [entity, setEntity] = useState<IEntity>({} as IEntity);
  const [sectors, setSectors] = useState<Sector[]>([]);
  const [addressState, setAddressState] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSectorModalVisible, setIsSectorModalVisible] = useState(false);

  const nameRef = useRef<Input>(null);
  const descriptionRef = useRef<TextArea>(null);
  const emailRef = useRef<Input>(null);
  const cnpjRef = useRef<MaskedInput>(null);
  const zipCodeRef = useRef<MaskedInput>(null);
  const streetRef = useRef<Input>(null);
  const numberRef = useRef<Input>(null);
  const complementRef = useRef<Input>(null);
  const districtRef = useRef<Input>(null);
  const cityRef = useRef<Input>(null);
  const locationRef = useRef<Input>(null);
  const radiusRef = useRef<Input>(null);

  const sectorNameRef = useRef<Input>(null);
  const sectorColorRef = useRef<Input>(null);

  const history = useHistory();
  const { id } = useParams() as { id: string };

  useEffect(() => {
    async function loadData(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get(`entities/${id}`);

        setEntity(response.data);
        setSectors(response.data.sectors);
      } catch (err) {
        message.error('Erro ao carregar dados');
      } finally {
        setLoading(false);
      }
    }

    if (id !== 'add') {
      setIsUpdating(true);
      loadData();
    }
  }, [id]);

  const handleAddEntity = useCallback((): void => {
    setLoading(true);

    api
      .post('entities', {
        name: nameRef.current?.input.value,
        email: emailRef.current?.input.value,
        description: descriptionRef.current?.state.value,
        sectors,
        cnpj: cnpjRef.current?.input.value,
        address: {
          zipCode: zipCodeRef.current?.input.value,
          street: streetRef.current?.input.value,
          number: numberRef.current?.input.value,
          complement: complementRef.current?.input.value,
          district: districtRef.current?.input.value,
          city: cityRef.current?.input.value,
          radius: radiusRef.current?.input.value,
          location: locationRef.current?.input.value,
          state: addressState,
        },
      } as IEntity)
      .then(() => {
        message.success('Entidade atualizada com sucesso');
        history.push('/entities');
      })
      .catch(() => message.error('Erro ao atualizar entidade'))
      .finally(() => setLoading(false));
  }, [addressState, history, sectors]);

  const handleUpdateEntity = useCallback((): void => {
    setLoading(true);

    api
      .put(`entities/${id}`, {
        name: nameRef.current?.input.value,
        email: emailRef.current?.input.value,
        description: descriptionRef.current?.state.value,
        sectors,
        cnpj: cnpjRef.current?.input.value,
        address: {
          zipCode: zipCodeRef.current?.input.value,
          street: streetRef.current?.input.value,
          number: numberRef.current?.input.value,
          complement: complementRef.current?.input.value,
          district: districtRef.current?.input.value,
          city: cityRef.current?.input.value,
          radius: radiusRef.current?.input.value,
          location: locationRef.current?.input.value,
          state: addressState,
        },
      } as IEntity)
      .then(() => {
        message.success('Entidade criada com sucesso');
        history.push('/entities');
      })
      .catch(() => message.error('Erro ao criar entidade'))
      .finally(() => setLoading(false));
  }, [addressState, history, id, sectors]);

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      if (isUpdating) {
        handleUpdateEntity();
      } else {
        handleAddEntity();
      }
    },
    [handleAddEntity, handleUpdateEntity, isUpdating],
  );

  const handleAddSector = (): void => {
    const sectorName = sectorNameRef.current?.input.value;

    if (!sectorName) {
      message.warning('Preencha o nome do setor');
      return;
    }

    setSectors(prevState => [
      ...prevState,
      {
        name: sectorName,
        color: sectorColorRef.current?.input.value || '#000',
      },
    ]);

    sectorNameRef.current?.setValue('');
    sectorColorRef.current?.setValue('#000000');
    setIsSectorModalVisible(false);
  };

  const handleRemoveSector = (index: number): void => {
    const updatedSectors = sectors.filter((_, i) => i !== index);

    setSectors(updatedSectors);
  };

  if (loading) {
    return (
      <Layout title="Entidades">
        <Spin style={{ marginTop: '50%' }} size="large" />
      </Layout>
    );
  }

  return (
    <Layout title="Entidades">
      <Container>
        <h2>{isUpdating ? 'Atualizar' : 'Adicionar'}</h2>

        <AddForm onSubmit={handleSubmit}>
          <div className="input-group">
            <h4>Nome</h4>
            <Input
              type="text"
              ref={nameRef}
              defaultValue={entity.name}
              required
            />
          </div>

          <div className="input-group">
            <h4>Descrição</h4>
            <TextArea ref={descriptionRef} defaultValue={entity.description} />
          </div>

          <div className="inputs-group">
            <div className="input-group">
              <h4>Email</h4>
              <Input type="email" ref={emailRef} defaultValue={entity.email} />
            </div>
            <div className="input-group">
              <h4>CNPJ</h4>
              <MaskedInput
                mask="11.111.111/1111-11"
                type="text"
                ref={cnpjRef}
                defaultValue={entity.cnpj}
              />
            </div>
          </div>

          <Divider />

          <Sectors>
            <div id="title-control">
              <h3>Setores:</h3>
              <Button
                icon={<MdAdd />}
                type="default"
                onClick={() => setIsSectorModalVisible(true)}
              >
                Incluir
              </Button>
            </div>

            <Modal
              title="Setor"
              centered
              visible={isSectorModalVisible}
              onCancel={() => setIsSectorModalVisible(false)}
              footer={[
                <Button
                  key="back"
                  onClick={() => setIsSectorModalVisible(false)}
                >
                  Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={handleAddSector}>
                  Pronto
                </Button>,
              ]}
            >
              <div className="input-group">
                <h4>Nome</h4>
                <Input type="text" ref={sectorNameRef} />
              </div>
              <div className="input-group">
                <h4>Cor</h4>
                <Input type="color" ref={sectorColorRef} />
              </div>
            </Modal>

            {sectors.length > 0 && (
              <List
                loading={loading}
                itemLayout="horizontal"
                dataSource={sectors}
                style={{ width: '50%' }}
                renderItem={(item, index) => (
                  <List.Item
                    actions={[
                      <button
                        className="sector-item-button"
                        type="button"
                        onClick={() => handleRemoveSector(index)}
                      >
                        <MdClose color="#f55d42" />
                      </button>,
                    ]}
                  >
                    <Badge color={item.color || 'cyan'} text={item.name} />
                  </List.Item>
                )}
              />
            )}
          </Sectors>

          <Divider />

          <div className="form-group">
            <strong>Endereço:</strong>
            <div className="inputs-group">
              <div className="input-group">
                <h4>CEP</h4>
                <MaskedInput
                  mask="11111-111"
                  type="text"
                  defaultValue={entity.address?.zipCode}
                  ref={zipCodeRef}
                />
              </div>
              <div className="input-group">
                <h4>Rua</h4>
                <Input
                  type="text"
                  ref={streetRef}
                  defaultValue={entity.address?.street}
                />
              </div>
              <div className="input-group">
                <h4>Número</h4>
                <Input
                  type="text"
                  ref={numberRef}
                  defaultValue={entity.address?.number}
                />
              </div>
            </div>
            <div className="inputs-group">
              <div className="input-group">
                <h4>Complemento</h4>
                <Input
                  type="text"
                  ref={complementRef}
                  defaultValue={entity.address?.complement}
                />
              </div>
              <div className="input-group">
                <h4>Bairro</h4>
                <Input
                  type="text"
                  ref={districtRef}
                  defaultValue={entity.address?.district}
                />
              </div>
              <div className="input-group">
                <h4>Cidade</h4>
                <Input
                  type="text"
                  ref={cityRef}
                  defaultValue={entity.address?.city}
                />
              </div>
              <div className="input-group">
                <h4>Estado</h4>
                <Select
                  defaultValue={entity.address?.state}
                  onChange={e => setAddressState(e)}
                >
                  {states.map(state => (
                    <Select.Option key={state.key} value={state.key}>
                      {state.value}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="inputs-group">
              <div className="input-group">
                <h4>Localização (lat, long)</h4>
                <Input
                  type="text"
                  ref={locationRef}
                  defaultValue={entity.address?.location}
                />
              </div>
              <div className="input-group">
                <h4>Raio</h4>
                <Input
                  type="text"
                  ref={radiusRef}
                  defaultValue={entity.address?.radius}
                />
              </div>
            </div>
          </div>

          <Divider />

          <div className="button-group">
            <Button
              icon={<MdClose />}
              type="primary"
              danger
              onClick={() => history.goBack()}
            >
              Cancelar
            </Button>
            <Button icon={<MdAdd />} htmlType="submit" type="primary">
              {isUpdating ? 'Atualizar' : 'Adicionar'}
            </Button>
          </div>
        </AddForm>
      </Container>
    </Layout>
  );
};

export default EntitiesForm;
