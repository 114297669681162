import React, {
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button, Divider, Input, message, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { MdAdd, MdClose } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import Layout from '../../../components/Layout';
import api from '../../../services/api';

import { Container, AddForm } from './styles';

interface Quadrant {
  _id: string;
  name: string;
  color: string;
  description: string;
}

const QuadrantsForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [quadrant, setQuadrant] = useState<Quadrant>({} as Quadrant);
  const [isUpdating, setIsUpdating] = useState(false);

  const nameRef = useRef<Input>(null);
  const descriptionRef = useRef<TextArea>(null);
  const colorRef = useRef<Input>(null);

  const history = useHistory();
  const { id } = useParams() as { id: string };

  useEffect(() => {
    async function loadData(): Promise<void> {
      setLoading(true);
      try {
        const response = await api.get(`quadrants/${id}`);

        setQuadrant(response.data);
      } catch (err) {
        message.error('Erro ao carregar dados');
      } finally {
        setLoading(false);
      }
    }

    if (id !== 'add') {
      loadData();
      setIsUpdating(true);
    }
  }, [id]);

  const handleAddQuadrant = useCallback(() => {
    setLoading(true);

    api
      .post('quadrants', {
        name: nameRef.current?.input.value,
        color: colorRef.current?.input.value || '#000',
        description: descriptionRef.current?.state.value,
      })
      .then(() => {
        message.success('Quadrante criado com sucesso');
        history.push('/quadrants');
      })
      .catch(() => message.error('Erro ao criar quadrante'))
      .finally(() => setLoading(false));
  }, [history]);

  const handleUpdateQuadrant = useCallback(() => {
    setLoading(true);

    api
      .put(`quadrants/${id}`, {
        name: nameRef.current?.input.value,
        color: colorRef.current?.input.value || '#000',
        description: descriptionRef.current?.state.value,
      })
      .then(() => {
        message.success('Quadrante atualizada com sucesso');
        history.push('/quadrants');
      })
      .catch(() => message.error('Erro ao atualizar quadrante'))
      .finally(() => setLoading(false));
  }, [history, id]);

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();

    if (isUpdating) {
      handleUpdateQuadrant();
    } else {
      handleAddQuadrant();
    }
  };

  if (loading) {
    return (
      <Layout title="Quadrantes">
        <Spin style={{ marginTop: '50%' }} size="large" />
      </Layout>
    );
  }

  return (
    <Layout title="Quadrantes">
      <Container>
        <h2>{isUpdating ? 'Atualizar' : 'Adicionar'}</h2>

        <AddForm onSubmit={handleSubmit}>
          <div className="input-group">
            <h4>Nome</h4>
            <Input ref={nameRef} type="text" defaultValue={quadrant.name} />
          </div>

          <div className="input-group">
            <h4>Cor</h4>
            <Input ref={colorRef} type="color" defaultValue={quadrant.color} />
          </div>

          <div className="inputs-group">
            <div className="input-group">
              <h4>Descrição</h4>
              <TextArea
                ref={descriptionRef}
                defaultValue={quadrant.description}
              />
            </div>
          </div>

          <Divider />

          <div className="button-group">
            <Button
              icon={<MdClose />}
              type="primary"
              danger
              onClick={() => history.goBack()}
            >
              Cancelar
            </Button>
            <Button icon={<MdAdd />} type="primary" htmlType="submit">
              {isUpdating ? 'Atualizar' : 'Adicionar'}
            </Button>
          </div>
        </AddForm>
      </Container>
    </Layout>
  );
};

export default QuadrantsForm;
