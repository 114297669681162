import { Button, Col, Row, Statistic, message, Spin } from 'antd';
import React, { useEffect, useState } from 'react';

import Layout from '../../components/Layout';

import { Container } from './styles';

import api from '../../services/api';

const Dashboard: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [usersList, setUsersList] = useState('');

  useEffect(() => {
    async function loadData(): Promise<void> {
      setLoading(true);

      try {
        const response = await api.get('/users');

        const users = response.data;

        setUsersList(users.length);
      } catch (err) {
        message.error('Falha ao carregar dados');
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  if (loading) {
    return (
      <Layout title="Usuários">
        <Spin style={{ marginTop: '50%' }} size="large" />
      </Layout>
    );
  }

  return (
    <Layout title="Dashboard">
      <Container>
        <h1>Informações</h1>

        <Row className="chart" gutter={16}>
          <Col span={12}>
            <Statistic title="Usuários ativos" value={usersList} />
          </Col>
          {/* <Col span={12}>
            <Statistic title="Account Balance (BRL)" value={10} precision={2} />
            <Button
              className="update-button"
              style={{ marginTop: 16 }}
              type="primary"
            >
              Atualizar
            </Button>
          </Col> */}
          {/* <Col span={12}>
            <Statistic title="Usuários ativos" value={111} loading />
          </Col> */}
        </Row>
      </Container>
    </Layout>
  );
};

export default Dashboard;
