import { darken } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  > h2 {
    margin-bottom: 20px;
  }

  button {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    background: #048ba8;
    color: #fff;
    border-radius: 4px;
    padding: 10px 20px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
    transition: 0.2s;

    svg {
      color: #fff;
    }

    &:hover {
      background: ${darken('#048ba8', 0.15)};
      color: #fff;
    }
  }

  #controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .video-grid {
    margin-top: 20px;
    flex: 1;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    justify-content: space-between;
    row-gap: 25px;
  }

  /* @media (max-width: 1680px) {
    .video-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media (max-width: 1400px) {
    .video-grid {
      grid-template-columns: repeat(auto-fill, 1fr);
    }
  }

  @media (max-width: 1120px) {
    .video-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 710px) {
    .video-grid {
      grid-template-columns: repeat(1, 1fr);
    }
  } */
`;

export const VideoCard = styled.div`
  padding: 10px 20px;
  border-radius: 4px;
  width: fit-content;
  width: 310px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);

  h2 {
    font-size: 18px;
    white-space: nowrap;
    width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  #video-data {
    display: flex;
    align-items: center;

    img {
      height: 80px;
    }

    div {
      margin-left: 15px;
      display: flex;
      flex-direction: column;

      strong {
        white-space: nowrap;
        width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 16px;
      }

      > span {
        cursor: default;
        margin: 2px 0;
      }

      .edit {
        display: flex;
        align-items: center;
        color: #404040;
        cursor: pointer;
        transition: 0.2s;

        svg {
          margin-left: 5px;
        }
      }
    }
  }
`;
